<template>
  <div
    @click="$emit('click')"
    class="selectablecard mx-2"
    :class="{
      selectablecard__disabled: disabled,
    }"
  >
    <div v-if="$slots.icon || icon" class="selectablecard__icon">
      <slot v-if="$slots.icon" name="icon"></slot>
      <v-icon :class="`${!!iconClass ? iconClass : ''}`" large style="width:36px;" v-else>{{ icon }}</v-icon>
    </div>
    <div class="selectablecard__title">
      <span class="selectablecard__title__text">{{ title }}</span>
    </div>
    <div class="selectablecard__description">
      <span class="selectablecard__description__text">
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectableCardsItem",
  props: {
    icon: {
      type: String,
    },
    iconClass: {
      type: String
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style lang="scss">
.selectablecard {
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 16px;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 6px rgba(57, 73, 76, 0.35);
  cursor: pointer;
  &.selected,
  &:hover {
    color: #43a047;
    background-color: white;
    box-shadow: 0 2px 10px 1px rgba(57, 73, 76, 0.4), 0 1px 2px rgba(57, 73, 76, 0.25);
    .selectablecard__icon .v-icon {
      color: #4caf50 !important;
      caret-color: #4caf50 !important;
    }
  }
  &__disabled {
    pointer-events: none;
    background-color: rgba(0,0,0,0.2)
  }
  &.selected {
    border: 2px solid #43a047;
  }
  &__icon {
    &__background {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 18px;
    margin-top: 4px;
  }
  &__description {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    font-size: 14px;
  }
}
.theme--dark {
  .selectablecard {
    &__disabled {
      background-color: rgba(255,255,255,0.2)
    }
  }
}
</style>
