<template>
  <v-layout row xs12 class="pa-2" wrap justify-space-around>
    <v-flex 
      class="mt-4"
      :class="itemContainerClasses"
      v-for="(item, i) in items"
      :key="i"
    >
      <SelectableCardsItem
        @click="item.onClick"
        :icon="item.iconType !== 'image' ? item.icon : null"
        :title="item.title"
        :description="item.description"
        :class="[item.class, selected === item.id && 'selected']"
        :iconClass="item.iconClass"
        :disabled="disabled"
      >
        <template 
          v-if="['image', 'background-image'].includes(item.iconType)" 
          v-slot:icon
        >
          <!-- Image must be on src/assets folder -->
          <img
            v-if="item.iconType === 'image'"
            :src="require(`@/assets/${item.icon}`)"
            style="width:50px"
          />
          <div
            v-else-if="item.iconType === 'background-image'"
            class="selectablecard__icon__background"
            :class="item.iconClass"
            :style="`background-image: url(${require(`@/assets/${item.icon}`)})`"
          />
        </template>
      </SelectableCardsItem>
    </v-flex>
  </v-layout>
</template>

<script>
import SelectableCardsItem from './SelectableCardsItem.vue';
export default {
  name: 'SelectableCards',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemContainerClasses: {
      type: Array,
      default: () => ['xs12', 'md12', 'lg6']
    },
    selected: {
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectableCardsItem
  },
};
</script>
