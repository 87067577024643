<template>
  <div>
    <v-card class="activate-plug-tile ma-2">
      <v-card-title
        ><h3 id="public-profile-tile">Public Profile</h3>
        <v-spacer></v-spacer>
        <v-icon
          id="public-profile-info"
          class="tooltip-icon ml-2"
          v-tooltip="{
            content: '* Fields are manditory',
            placement: 'top-center',
            trigger: 'click hover',
          }"
          >info</v-icon
        >
      </v-card-title>
      <v-alert :value="error" type="error" dismissible>
        {{ error }}
      </v-alert>
      <v-alert :value="success" type="success" dismissible>
        Public Profile Information Saved
      </v-alert>
      <v-card-text class="text-xs-center">
        <v-form ref="publicProfileForm" @submit="change()" v-model="valid">
          <v-text-field
            id="public-profile-manager-name-field"
            outline
            label="* Manager Name / Corporation"
            v-model="inputs.name"
            :error="error ? true : false"
            :rules="[rules.required]"
            required
            :maxlength="50"
            :disabled="busy || !hasAccess"
          />
          <p class="text-xs-left subheading">Contact Information:</p>
          <v-text-field
            id="public-profile-email-field"
            :maxlength="50"
            outline
            label="* Public E-mail"
            v-model="inputs.email"
            :rules="[rules.required, inputs.email ? rules.email : () => true]"
            :error="error ? true : false"
            :disabled="busy || !hasAccess"
          />
          <v-text-field
            id="public-profile-phone-number-field"
            outline
            label="Public Phone Number"
            v-model="inputs.phone"
            :error="error ? true : false"
            :disabled="busy || !hasAccess"
            :maxlength="30"
          />
          <v-textarea
            id="public-profile-message-field"
            v-model="inputs.message"
            outline
            :error="error ? true : false"
            :disabled="busy || !hasAccess"
            label="Message"
            counter="1000"
            rows="10"
            :maxlength="1000"
          />
          <p class="text-xs-justify subheading my-4">
            Configure information that you would like to display to Plugzio users. This information will be seen everytime a user attemps to activate your Plugzio device
          </p>
          <v-layout xs12>
            <v-btn id="public-profile-preview-button" class="mt-2" flat @click="showPreview = true" :loading="busy" :disabled="busy">
              PREVIEW
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              id="public-profile-save-button"
              class="plugzio-button mt-2"
              @click="savePublicProfile()"
              outline
              round
              :loading="busy"
              :disabled="busy || !hasAccess"
            >
              SAVE
            </v-btn>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showPreview" max-width="350">
      <v-card>
        <v-card-title>
          <h3 id="device-activation-dialog">Device Activation</h3>
          <v-spacer></v-spacer>
          <v-tooltip bottom :offset-y="-10">
            <template v-slot:activator="{ on }">
              <v-icon class="tooltip-icon mb-0 pull-right" v-on="on">
                info
              </v-icon>
            </template>
            <span class="tooltip-content">
              This is a preview of what users will see when attempting to activate your device. 
              Note that the payment plan information is for display purposes only.
            </span>
        </v-tooltip>
        </v-card-title>
        <v-card-text class="text-xs-left">
          <v-flex xs12>
            <div><h4 class="border-bottom">Device</h4></div>
            <div class="mt-2">
              <p style="overflow-wrap:anywhere;display:flex;align-items:center">
                <span id="device-activation-managed-message" class="flex flex-1">
                  Pricing and access of device <strong>&lt;QR Code&gt;</strong> is set by <strong>{{ inputs.name }}.</strong>
                </span>
                <button
                  id="device-activation-contact-button"
                  class="v-btn--small pa-1 button-onlyicon d-flex align-center ml-2 white-space__nowrap"
                    @click="showManagementInformation = true" 
                >
                  <v-icon size="18" style="margin: 0" key="icon">contact_mail</v-icon>
                  <span class="ml-2" key="description">Contact</span>
                </button>
              </p>
            </div>
          </v-flex>
          <v-flex xs12 class="mt-4">
            <div><h4 class="border-bottom">Payment Plan</h4></div>
            <div id="device-activation-payment-plan" class="mt-2">
              <p class="mb-0">Currency: <strong>USD</strong></p>
              <p class="mb-0">Activation Fee: <strong>$1.00</strong></p>
              <p class="mb-0">Duration Fee: <strong>$0.25 / h</strong></p>
              <p>Consumption Rate: <strong>$0.10 / kWh</strong></p>
            </div>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            id="device-activation-exit-preview-button"
            color="green"
            flat="flat"
            @click="showPreview = false"
          >
            Exit Preview
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <management-information v-model="showManagementInformation" :name="inputs.name" :phone="inputs.phone" :email="inputs.email" :message="inputs.message" :preview="true" />
  </div>
</template>

<script>
import mixin  from "./../mixins";
import ManagementInformation from "./modals/ManagementInformation";
import Api from "@library/apis/Api";
import { OwnerProxyHelpers } from '@/library/helpers';

export default {
  mixins: [mixin],
  components: {
    ManagementInformation,
  },
  data() {
    return {
      busy: false,
      error: null,
      inputs: {
        name: null,
        email: null,
        phone: null,
        message: null,
      },
      showPreview: false,
      showManagementInformation: false,
      success: false,
      valid: true,
    };
  },
  computed: {
    hasAccess() {
      return OwnerProxyHelpers.isHaveAccessToFeature("SETTING_PUBLIC_PROFILE")
    }
  },
  watch: {
    showManagementInformation: function(value) {
      if (value) this.showPreview = false;
      else this.showPreview = true;
    },
  },
  methods: {
    savePublicProfile() {
      if (this.$refs.publicProfileForm.validate()) {
        this.busy = true;
        this.success = false;
        this.error = null;
        Api.ownerSetPublicProfile({ publicProfile: this.inputs })
          .then(() => {
            this.success = true;
          })
          .catch((error) => {
            this.error = error.response.data;
          })
          .finally(() => {
            this.busy = false;
          });
      }
    },
    init() {
      this.busy = true;
      Api.ownerGetPublicProfile()
        .then((data) => {
          if (!data) return;
          if (data.hasOwnProperty("name")) this.inputs.name = data.name;
          if (data.hasOwnProperty("email")) this.inputs.email = data.email;
          if (data.hasOwnProperty("phone")) this.inputs.phone = data.phone;
          if (data.hasOwnProperty("message")) this.inputs.message = data.message;
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.busy = false;
        });
    },
  },
  mounted() {
    this.init();
  }
};
</script>
