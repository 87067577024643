<template>
  <div class="open-app-snackbar" :class="{ active: show }">
    <div class="open-app-snackbar__overlay" />

    <div class="open-app-snackbar__body">
      <div class="open-app-snackbar__title">
        <h3>Open Plugzio in...</h3>
      </div>
      <div class="open-app-snackbar__option">
        <img class="open-app-snackbar__option__logo" src="../assets/favicon-32x32.png" />
        <span class="open-app-snackbar__option__text">Plugzio App</span>
        <v-btn class="open-app-snackbar__option__action plugzio-button" round small outline depressed @click="openApp">
          OPEN
        </v-btn>
      </div>
      <div class="open-app-snackbar__option">
        <img class="open-app-snackbar__option__logo" src="../assets/browser.png" />
        <span class="open-app-snackbar__option__text">Browser</span>
        <v-btn class="open-app-snackbar__option__action" round small outline color="rgba(0,0,0,0.5)" @click="$store.commit('Global/setShowOpenAppSnackbar', false)">
          Continue
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenAppSnackbar",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    openApp() {
      this.$store.commit("Global/setShowOpenAppSnackbar", false);
      
      if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.replace("https://play.google.com/store/apps/details?id=io.plugz.my&hl=en_CA&gl=US");
      } else {
        window.location.replace("https://apps.apple.com/us/app/plugzio/id1617444866");
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
.open-app-snackbar {
  height: 100vh;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 999999;
  transform: translateY(200px);
  opacity: 0;
  transition: 0.3s all ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
  &__title {
    display: flex;
    justify-content: center;
    padding: 1rem;
    font-weight: bold;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  &__body {
    height: 200px;
    background: white;
  }
  &__overlay {
    height: calc(100% - 200px);
    background-color: rgba(0, 0, 0, 0.3);
  }
  &__option {
    display: flex;
    height: 50px;
    margin-top: 1rem;
    padding: 0 1rem;
    align-items: center;
    justify-content: flex-start;
    &__logo {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: 30px;
      height: 30px;
    }
    &__text {
      margin-left: 1rem;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      flex-grow: 1;
    }
    &__action {
      min-width: 100px;
    }
  }
}
</style>
