import { render, staticRenderFns } from "./OpenAppSnackbar.vue?vue&type=template&id=806cbe92&scoped=true&"
import script from "./OpenAppSnackbar.vue?vue&type=script&lang=js&"
export * from "./OpenAppSnackbar.vue?vue&type=script&lang=js&"
import style0 from "./OpenAppSnackbar.vue?vue&type=style&index=0&id=806cbe92&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "806cbe92",
  null
  
)

export default component.exports