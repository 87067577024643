<template>
  <div>
    <v-layout row class="mt-0 mb-0">
      <v-flex sm4 xs5>
        <v-text-field
          max-width="300"
          class="session-search mb-0 ml-2"
          v-model.lazy="search"
          :placeholder="!enableRegexSearch ? 'Search' : 'Search RegEx'"
          autocomplete="false"
          :disabled="$store.state.loading"
          prepend-icon="search"
        >
          <template v-if="enableRegexSearch" v-slot:prepend-inner>
            <v-flex>
              <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
            </v-flex>
          </template>
          <template v-slot:append>
            <v-flex align-self-center class="search-append-div">
              <i v-if="enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>

              <v-icon
                @click="enableRegexSearch = !enableRegexSearch"
                class="tooltip-icon"
                :color="enableRegexSearch ? `blue lighten-2` : ''"
                v-tooltip="{
                  content: tooltip,
                  trigger: 'hover',
                }"
                >settings_applications</v-icon
              >
            </v-flex>
          </template></v-text-field
        >
      </v-flex>
      <v-flex sm8 xs7 class="text-xs-right pt-3 pr-2" v-if="$store.state.loading">
        <v-progress-circular indeterminate color="orange" :size="24"></v-progress-circular>
      </v-flex>
      <v-flex sm8 xs7 class="text-xs-right page-toolbar pt-3" v-else>
        <v-menu offset-y>
          <v-btn id="outlet-status-filter-button" slot="activator" value="status" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Status: &nbsp;
              <strong>{{ filter.state.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up" :class="{ 'success--text': filter.state.property === 1, 'warning--text': filter.state.property === 0 }">power</v-icon>
          </v-btn>
          <v-list id="outlet-status-filter" hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.state.property === item.property }"
              v-for="(item, index) in filter.states"
              :key="index"
              @click="filter.state = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <v-btn id="outlet-power-filter-button" slot="activator" value="status" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Power Settings: &nbsp;
              <strong>{{ filter.status.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">settings</v-icon>
          </v-btn>
          <v-list id="outlet-power-filter" hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.status.property === item.property }"
              v-for="(item, index) in filter.statuses"
              :key="index"
              @click="filter.status = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <v-btn id="outlet-show-filter-button" slot="activator" value="hideDevice" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Show Hidden Devices: &nbsp;
              <strong>{{ filter.showHiddenDevice ? "Yes" : "No" }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">hide_source</v-icon>
          </v-btn>
          <v-list id="outlet-hide-filter" hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.showHiddenDevice === item.property }"
              v-for="(item, index) in filter.hiddenOptions"
              :key="index"
              @click="filter.showHiddenDevice = item.property"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>

        <v-menu offset-y>
          <v-btn id="outlet-sort-filter-button" slot="activator" value="order" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Sort: &nbsp;
              <strong>{{ filter.sorting.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
          </v-btn>
          <v-list id="outlet-sort-filter" hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.sorting.property === item.property }"
              v-for="(item, index) in sortOptions"
              :key="index"
              @click="filter.sorting = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat small class="page-toolbar-btn" @click="filter.desc = !filter.desc" :disabled="!filter.sorting.property">
          <v-icon :class="{ 'rotate-icon': !filter.desc }">sort</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="mt-0 mb-2">
      <OutletCard 
        v-for="(plug, index) in filteredPlugs"
        :ref="`outletCard${plug.id}`"
        :id="'plug-id-' + plug.id"
        :highlight="highlight === plug.id ? true : false"
        :key="plug.identifier"
        :plug="plug"
        :plans="accessPlans"
        :is-hidden="filter.showHiddenDevice ? hiddenDevices.includes(plug.identifier) : false"
        :type="type"
        @edit="userAction"
        @changeMode="editMode"
        @show="(v) => (v ? expandedCards.push(plug.id) : (expandedCards = expandedCards.filter((e) => e != plug.id)))"
        @selection="v => handleSelection(v, plug)"
      />
    </v-layout>
    <v-dialog v-model="modeChangeModal" max-width="360">
      <v-card id="change-mode-settings" v-if="modeChange.id">
        <v-card-title>
          <h3>Change Power Settings</h3>
        </v-card-title>
        <v-alert id="error-change-power-settings" :value="modeError" type="error"><span v-html="modeError"></span></v-alert>
        <v-card-text v-if="errorUsers == 0">
          Device ID:
          <strong>{{ modeChange.identifier }}</strong>
          <br />
          <div v-if="confirm" class="mt-2" transition="slide-y-transition">
            <strong>There is an ongoing session by {{ modeChange.username }}</strong>
            <p class="mt-2 mb-0 modal-description">
              Changing the device's mode will terminate the user's session. Are you sure you would like to continue?
            </p>
          </div>
          <v-layout
            xs12
            column
            justify-center
            v-else-if="
              !!modeError &&
                !modeError.includes('Device has been disabled due to expired subscription') &&
                ![errors.bluetoothTimebasedPower, errors.bluetoothTimebased].includes(modeError)
            "
            transition="slide-y-transition"
          >
            <p v-if="false" class="mt-4 mb-2">
              If the device is powered on but offline, you can always configure your device by Bluetooth.
            </p>
            <v-btn v-if="false" class="plugzio-button" outline round @click="$router.push('/owner/ble-configuration')">Configure Device By Bluetooth</v-btn>
          </v-layout>
          <!-- Delete this code -->
          <!-- <div v-else-if="modeError == errors.bluetoothTimebasedPower" transition="slide-y-transition">
            <v-autocomplete
              v-model="modeChange.plan_id"
              class="mt-3"
              :items="accessPlans"
              item-text="name"
              item-value="id"
              label="Payment Plan"
              no-data-text="No payment plans available"
              outline
              required
              :error="modeError || planError ? true : false"
              :disabled="$store.state.loading"
              hint="Default payment plan for users with no access."
              persistent-hint
            />
          </div> -->
          <div v-else transition="slide-y-transition">
            <v-select
              class="mt-3"
              v-model="modeChange.code"
              :items="plugModes"
              item-text="name"
              item-value="code"
              label="Power Settings"
              outline
              required
              :error="modeError ? true : false"
              :disabled="$store.state.loading"
              :hint="selectedMode ? selectedMode.description : ''"
              persistent-hint
            ></v-select>
            <template v-if="modeChange.code > 1">
              <h3 class="mt-4">Access Settings</h3>
              <v-select
                class="mt-3"
                v-model="modeChange.ispublic"
                :items="subModes"
                item-text="name"
                item-value="code"
                label="Access"
                outline
                required
                :error="modeError ? true : false"
                :disabled="$store.state.loading"
                :hint="accessDescription"
                persistent-hint
              ></v-select>
              <v-autocomplete
                v-if="modeChange.ispublic"
                v-model="modeChange.plan_id"
                :items="visiblePayments"
                item-text="name"
                item-value="id"
                label="Payment Plan"
                no-data-text="No payment plans available"
                outline
                required
                :error="modeError || planError ? true : false"
                :disabled="$store.state.loading"
                hint="Default payment plan for users with no access."
                persistent-hint
              />
            </template>
          </div>
        </v-card-text>
        <v-card-text v-else>
          <p class="text-xs-left mt-4">Users on time-based payment plans:</p>
          <div class="text-xs-left">
            <ul>
              <li v-for="(user, i) in errorUsers" :key="i">
                <strong>{{ user }}</strong>
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="errorUsers.length == 0" color="gray" flat="flat" @click="modeChangeModal = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="errorUsers.length > 0"
            color="green darken-1"
            flat="flat"
            :loading="!!$store.state.loading"
            @click="
              modeChangeModal = false;
              errorUsers = [];
            "
          >
            OK
          </v-btn>
          <v-btn
            v-else-if="!!modeError && !modeError.includes('Device has been disabled due to expired subscription') && modeError != errors.bluetoothTimebasedPower"
            color="green darken-1"
            flat="flat"
            :loading="!!$store.state.loading"
            @click="modeError = null"
          >
            RETRY
          </v-btn>
          <v-btn
            v-else-if="confirm"
            color="green darken-1"
            flat="flat"
            :loading="!!$store.state.loading"
            @click="
              confirm = false;
              checkPlanAndSaveMode();
            "
          >
            CONFIRM
          </v-btn>
          <v-btn v-else color="green darken-1" flat="flat" :loading="!!$store.state.loading" @click="modeChange.username ? (confirm = true) : checkPlanAndSaveMode()">
            SUBMIT
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userAccessModal" max-width="320">
      <v-card id="change-access-settings" v-if="edit">
        <v-card-title>
          <h3>{{ modalTitle }}</h3>
        </v-card-title>
        <v-alert :value="error" type="error"><span v-html="error"></span></v-alert>
        <v-card-text v-show="!revoke">
          <div v-if="mode === 'edit'">
            Username:
            <strong>{{ edit.user.username }}</strong>
          </div>
          <div class="mb-3">
            Device ID:
            <strong>{{ edit.plug.identifier }}</strong>
          </div>
          <v-text-field
            v-if="mode !== 'edit'"
            v-model="edit.user.username"
            label="User's E-mail"
            outline
            autocomplete="false"
            required
            :error="error ? true : false"
            :disabled="$store.state.loading"
            class="mt-3"
          ></v-text-field>
          <v-autocomplete
            v-if="edit"
            v-model="edit.user.plug_payment_access_plan_id"
            :items="visiblePayments"
            item-text="name"
            item-value="id"
            label="Payment Plan"
            no-data-text="No payment plans available"
            outline
            required
            :error="!!error"
            :disabled="$store.state.loading"
          />
        </v-card-text>
        <v-card-text v-show="revoke" class="text-xs-left">
          Username:
          <strong>{{ edit.user.username }}</strong>
          <br />Payment Plan:
          <strong>{{ edit.user.plan ? edit.user.plan.params.description : "No Payment Plan" }}</strong>
          <br />Device ID:
          <strong>{{ edit.plug.identifier }}</strong>
        </v-card-text>
        <v-card-actions>
          <v-btn color="gray" flat="flat" @click="userAccessModal = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <send-email v-if="showSendEmailButton" :username="edit.user.username" />
          <v-btn v-else :color="revoke ? 'red' : 'green darken-1'" flat="flat" :loading="!!$store.state.loading" @click="checkPlanAndSave()">{{
            revoke ? "CONFIRM" : "SUBMIT"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import plugAccess from "./../mixins/plugAccess";
import plugModes from "./../mixins/plugModes";
import sort from "./../mixins/sort";
import VueScrollTo from "vue-scrollto";
import PlugHelpers from "./../library/helpers/PlugHelpers";
import Api from "@library/apis/Api";
import OutletCard from "@/components/OutletCard";
import SendEmail from "./buttons/SendEmail";

export default {
  name: "OutletsTile",
  props: {
    highlight: {
      type: Number
    },
    type: {
      type: String,
      default: "expandable"
    }
  },
  mixins: [plugAccess, plugModes, sort],
  components: {
    OutletCard,
    SendEmail,
  },
  data() {
    return {
      enableRegexSearch: false,
      errorUsers: [],
      search: null,
      expandedCards: [],
      filter: {
        desc: false,
        state: {
          label: "All",
          property: "all",
        },
        states: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "On",
            property: 1,
          },
          {
            label: "Off",
            property: 0,
          },
        ],
        status: {
          label: "All",
          property: "all",
        },
        statuses: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "Smart",
            property: 2,
          },
          {
            label: "Always On",
            property: 1,
          },
          {
            label: "Always Off",
            property: 0,
          },
          // to hide bluetooth status
          // {
          //   label: "Bluetooth",
          //   property: 3,
          // },
        ],
        showHiddenDevice: false,
        hiddenOptions: [
          {
            label: "Yes",
            property: true,
          },
          {
            label: "No", 
            property: false,
          },
        ],
        sorting: {
          label: "Device ID",
          property: "identifier",
        },
        sort: [
          {
            label: "Device ID",
            property: "identifier",
          },
          {
            label: "Description",
            property: "description",
          },
          {
            label: "Power Settings",
            property: "mode",
          },
          {
            label: "Access Settings",
            property: "access_settings",
          },
          {
            label: "Service Tier",
            property: "service_tier_id",
          },
          {
            label: "Serial Number",
            property: "serial_no",
          }
        ],
      },
      tooltip: `Allows search results to be filtered using regular expression (RegEx) patterns. Regex flavour is JavaScript/ECMAScript.<br/>
                Examples:
                <ul>
                  <li>(Jan)|(Aug) - Pattern match results with either "Jan" or "Aug".</li>
                  <li>(My).{1,}(Unit) - Pattern match results that have pattern starting with "My and ending with "Unit" (e.g. "MySpecialUnit")</li>
                </ul>`,
      selectedPlugs: []
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      role: (state) => state.Auth.role
    }),
    ...mapGetters(["plugs", "accessPlans"]),
    ...mapGetters("PaymentPlan", { 
      hiddenPayments: "hiddenPayments",
      visiblePayments: "visiblePayments"
    }),
    ...mapGetters("Device", { 
      hiddenDevices: "hiddenDevices"
    }),
    filteredPlugs() {
      let plugs = JSON.parse(JSON.stringify(this.plugs));
      const formatted_filters = {
        state: this.filter.state.property == "all" ? "all" : parseInt(this.filter.state.property),
        status: this.filter.status.property == "all" ? "all" : parseInt(this.filter.status.property),
        sort_by: this.filter.sorting.property,
        sort_direction_desc: this.filter.desc,
        search: this.search,
        enableRegexSearch: this.enableRegexSearch,
      };
      const hiddenDevices = this.hiddenDevices;
      if (!this.filter.showHiddenDevice) { 
        plugs = plugs.filter(plug => !hiddenDevices.includes(plug.identifier));
      }
      return PlugHelpers.filter(plugs, formatted_filters);
    },
    simplePlans() {
      return this.accessPlans.filter((plan) => !plan.params.timezone);
    },
    showSendEmailButton() {
      if (this.error && this.error === "User does not exist") return this.edit.user.username;
      return false;
    },
    sortOptions() {
      const options = this.filter.sort; 
      const isAdmin = this.role === 'admin';

      return isAdmin ? options : options.filter(item => item.property !== 'service_tier_id' && item.property !== 'serial_no');
    }
  },
  watch: {
    filteredPlugs(plugs) {
      this.$emit("plugsChanged", plugs);
    },
  },
  methods: {
    checkPlanAndSave() {
      const plug = this.edit.plug;
      const user = this.edit.user;
      const selectedPlan = this.accessPlans.find((plan) => plan.id == user.plug_payment_access_plan_id);

      this.error = null;
      if (this.revoke == false && plug.plugmode == 3 && !!selectedPlan.params.timezone) this.error = this.errors.bluetoothTimebased;
      else this.save();
    },
    checkPlanAndSaveMode() {
      this.$store.dispatch("loading", true);
      if (!this.modeChange.ispublic) {
        return this.saveMode();
      }
      const selectedPlan = this.getSelectedPlan(this.modeChange.plan_id);
      const timezone = selectedPlan && selectedPlan.params ? selectedPlan.params.timezone : null;
      // if (this.modeChange.code == 3 && !!timezone) this.modeError = this.modeChange.changeAccess ? this.errors.bluetoothTimebased : this.errors.bluetoothTimebasedPower;
      if (this.modeChange.code == 3 && !!timezone) this.modeError = this.errors.bluetoothTimebased;
      else {
        // this.saveMode(!this.modeChange.changeAccess && this.modeChange.code == 3);
        // this.saveMode(this.modeChange.code == 3);
        if (this.modeChange.code !== 3) {
          return this.saveMode();
        }
        this.checkUsers()
          .then((errors) => {
            if (errors.length == 0) return this.saveMode();
            this.modeError = this.errors.bluetoothTimebasedUsers;
            this.errorUsers = errors;
          })
          .catch((error) => {
            console.log(error);
            this.modeChangeModal = false;
          })
          .finally(() => {
            this.$store.dispatch("loading", false);
          });
      }
    },
    checkUsers() {
      return new Promise((resolve, reject) => {
        const errors = [];
        Api.plugUsers("owner", `{"plugId":${this.modeChange.id}}`)
          .then((plugUsers) => {
            const users = plugUsers || [];
            if (users.length == 0) return resolve(errors);
            for (let i = 0; i < users.length; i++) {
              const user = users[i];
              const simple_plan = this.simplePlans.find((plan) => plan.id == user.plug_payment_access_plan_id);
              if (!simple_plan) errors.push(user.username);
            }
            resolve(errors);
          })
          .catch(reject);
      });
    },
    getSelectedPlan(plan_id) {
      const selectedPlan = this.accessPlans.find((plan) => plan.id == plan_id);
      return selectedPlan;
    },
    saveMode() {
      if (this.modeChange.code > 1 && this.modeChange.ispublic && !this.modeChange.plan_id) {
        this.planError = true;
        this.$store.dispatch("loading", false);
        return false;
      }

      let setModeStatus = true;
      let setAccessStatus = true;
      

      const setMode = () => {
        return new Promise((resolve, reject) => {
          Api.plugSetMode(this.role, `{"plugId":"${this.modeChange.id}","mode":${this.modeChange.code}}`)
            .then(resolve)
            .catch((err) => {
              setModeStatus = false;
              reject(err);
            });
        });
      };

      const setAccess = () => {
        return new Promise((resolve, reject) => {
          Api.plugSetAccess(
            this.role,
            this.modeChange.ispublic ? "public" : "private",
            this.modeChange.ispublic ? `{"plugId":"${this.modeChange.id}","planId":"${this.modeChange.plan_id}"}` : `{"plugId":"${this.modeChange.id}"}`
          )
            .then(resolve)
            .catch((err) => {
              setAccessStatus = false;
              reject(err);
            });
        });
      };
      this.$store.dispatch("loading", true);
      Promise.all([setMode(), setAccess()])
        .then((resMode, resAccess) => {
          console.log({ resMode, resAccess });
          // setTimeout(() => { removed setTimeout function after successful Promise. 
            this.$store.dispatch("loading", false);
            this.$store.dispatch("snackbar", {
              message: "Device mode change request sent",
            });
            this.$store.dispatch("loadPlugs");
            this.$store.dispatch("editPlugUser", this.modeChange.id);

            this.modeChangeModal = false;
            this.modeError = null;
          // }, 1500);
        })
        .catch((error) => {
          setTimeout(() => {
            this.$store.dispatch("loading", false);
            let errorMessage = error.response.data;
            if (setModeStatus && !setAccessStatus)
              errorMessage = `Device's power settings has changed, but the device's access settings has not been changed. Please contact customer support using the "Chat With Us" button on the navigation bar.`;
            else if (!setModeStatus && setAccessStatus)
              errorMessage = `Access settings has successfully been changed, but the device's power settings has not been changed. Please make sure the device is online and connected to the internet.`;
            else if (!setModeStatus && !setAccessStatus)
              errorMessage = `Failed to change both device access and power settings. Please contact customer support using the "Chat With Us" button on the navigation bar.`;

            this.modeError =
              error.response.data === "Device has been disabled"
                ? `Device has been disabled due to expired subscription. Please contact customer support using the "Chat With Us" button on the navigation bar.`
                : errorMessage;
            this.$store.dispatch("loadPlugs");
          }, 1500);
        });
    },
    handleSelection(is_selected, plug) {
      if (is_selected) this.selectedPlugs.push(plug.identifier)
      else this.selectedPlugs = this.selectedPlugs.filter(identifier => identifier !== plug.identifier)
      this.$emit("selection", this.selectedPlugs)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("plugsChanged", this.filteredPlugs)
      if (this.highlight && this.plugs)
        VueScrollTo.scrollTo("#plug-id-" + this.highlight, 800, {
          offset: -74,
        });
    });
    if (this.hiddenPayments.includes(this.modeChange.plan_id)) {
      this.modeChange.plan_id = null
    }
  },
};
</script>
<style lang="scss">
.search-slash-icon {
  color: rgba(0, 0, 0, 0.87);
}
.search-append-div {
  white-space: nowrap;
}
</style>
