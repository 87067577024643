<template>
  <v-flex lg4 md6 sm12>
    <v-card
      class="session-tile session-history outlet-card ma-2" 
      :class="{
        'owner-session': plug.plugmode > 1,
        'outlet-card__hidden' : isHidden,
        'session-tile__selected': selected, 
        'session-tile__dark': darkTheme && selected
      }"
    >
      <v-card-text
        class="pl-1 py-2 pr-0"
        :class="{ close: !show, pointer: plug.plugmode > 1 }"
        @click.stop="hasAccessToTile.MODE || hasAccessToTile.SET_ACCESS ? handleClick : !show"
      >
        <v-list three-line>
          <v-list-tile avatar class="d-flex ma-0">
            <div class="d-flex outlet-card__container">
              <div class="d-flex justify-center align-center outlet__container">   
                <v-list-tile-avatar>
                  <div class="plug-avatar plug-lists-avatar" :class="iconClass">
                    <img src="./../assets/plug-off-always.png" v-if="plug.plugmode === 0" />
                    <img src="./../assets/plug-on-always.png" v-else-if="plug.plugmode === 1" />
                    <!-- <img src="./../assets/bluetooth.png" v-else-if="plug.plugmode === 3" /> -->
                    <img src="./../assets/plug-on.png" v-else-if="plug.active" />
                    <img src="./../assets/plug-off.png" v-else />
                  </div> 
                </v-list-tile-avatar>
                <v-list-tile-content class="plug-tile-details pl-3">
                  <div class="plug-tile-details-text">
                    Device ID:
                    <span class="black--text">{{ plug.identifier }}</span>
                  </div>
                  <div :class="show ? `plug-tile-details-long-text` : `plug-tile-details-text`">
                    Description:
                    <span class="black--text">{{ plug.description }}</span>
                  </div>
                  <div v-if="plug.plugmode > 1 && plug.ispublic" :class="show ? `plug-tile-details-long-text` : `plug-tile-details-text`">
                    Public Payment Plan:
                    <span class="black--text">{{ plug.access_plan }}</span>
                  </div>

                  <template v-if="role == 'admin'">
                    <div class="plug-tile-details-text">
                      Service Tier:
                      <span class="black--text">{{ plug.service_tier_id }}</span>
                    </div>
                    <div class="plug-tile-details-text">
                      Serial Number:
                      <span class="black--text">{{ plug.serial_no }}</span>
                    </div>
                  </template>

                  <v-layout
                    v-if="showActions"
                    row
                    wrap
                    class="session-details outlet-buttons"
                  >
                    <v-flex xs7 text-xs-left>
                      <button class="outlet-button mt-1" @click.stop="changeMode(plug)" :disabled="!hasAccessToTile.MODE" :class="{ 'disabled__device-tile' : !hasAccessToTile.MODE }">
                        <v-icon class="outlet-icon ma-0" size="18" :color="getIconColor" v-if="plug.plugmode === 0">block</v-icon>
                        <v-icon class="outlet-icon ma-0" size="18" :color="getIconColor"  v-else-if="plug.plugmode === 1">update</v-icon>
                        <img v-else-if="plug.plugmode === 2" class="outlet-icon outlet-icon-smart ma-0" :src="getImage" width="16px" height="16px" />
                        <!-- BLE Referrence 
                          <img
                          v-else-if="plug.plugmode === 3"
                          class="outlet-icon ma-0"
                          src="./../assets/bluetooth-zoomed.png"
                          width="16px"
                          height="16px"
                          style="margin-bottom:-4px!important;"
                        /> -->
                        <span :class="{ 'mr-4': plug.plugmode > 1, 'disabled__device-tile-text' : !hasAccessToTile.MODE }" class="outlet-button-title">{{ plug.mode }}</span>
                        <template v-if="plug.plugmode > 1">
                          <v-icon class="outlet-icon ma-0" v-if="!plug.ispublic" size="18" :color="getIconColor">lock</v-icon>
                          <v-icon class="outlet-icon ma-0" v-else size="18" :color="getIconColor">lock_open</v-icon>
                          <span class="outlet-button-title" :class="{ 'disabled__device-tile-text' : !hasAccessToTile.MODE }">{{ plug.ispublic ? "PUBLIC" : "PRIVATE" }}</span>
                        </template>
                      </button>
                    </v-flex>
                    <v-flex xs5 class="text-xs-right">
                      <button v-if="plug.plugmode > 1" class="outlet-button outlet-button-secondary mt-1" @click.stop="addUser(plug)" :disabled="!hasAccessToTile.SET_ACCESS" :class="{ 'disabled__device-tile' : !hasAccessToTile.SET_ACCESS }">
                        <span class="outlet-button-title" :class="{ 'disabled__device-tile-text' : !hasAccessToTile.SET_ACCESS }">ADD USER</span>
                      </button>
                      <button @click.stop="editPlug(plug)" class="outlet-button outlet-button-secondary outlet-arrow mt-1 ml-2" :disabled="!hasAccessToTile.SET_ACCESS" :class="{ 'disabled__device-tile' : !hasAccessToTile.SET_ACCESS }">
                        <v-icon size="18" style="margin: 0 0 -3px 0">edit</v-icon>
                      </button>
                      <button id="toggle-user-plugs-button" v-if="plug.plugmode > 1" class="outlet-button outlet-button-secondary outlet-arrow mt-1 ml-2" @click.stop="show = !show">
                        <v-progress-circular indeterminate color="orange" :size="18" small v-if="busy"></v-progress-circular>
                        <template v-else>
                          <v-icon size="18" style="margin: 0 0 -3px 0" v-if="show">keyboard_arrow_up</v-icon>
                          <v-icon size="18" style="margin: 0 0 -3px 0" v-else>keyboard_arrow_down</v-icon>
                        </template>
                      </button>
                    </v-flex>
                  </v-layout>
                </v-list-tile-content>
              </div>
              <div v-if="showActions" class="mt-2 outlet-card__footer">
                <v-layout row wrap class="session-details outlet-buttons">
                  <v-flex xs7 text-xs-left>
                    <button class="outlet-button mt-1" @click.stop="changeMode(plug)" :disabled="!hasAccessToTile.MODE" :class="{ 'disabled__device-tile' : !hasAccessToTile.MODE}">
                      <v-icon class="outlet-icon ma-0" size="18" :color="getIconColor"  v-if="plug.plugmode === 0">block</v-icon>
                      <v-icon class="outlet-icon ma-0" size="18" :color="getIconColor"  v-else-if="plug.plugmode === 1">update</v-icon>
                      <img v-else-if="plug.plugmode === 2" class="outlet-icon outlet-icon-smart ma-0" :src="getImage" width="16px" height="16px" />
                      <!-- BLE Reference 
                        <img
                        v-else-if="plug.plugmode === 3"
                        class="outlet-icon ma-0"
                        src="./../assets/bluetooth-zoomed.png"
                        width="16px"
                        height="16px"
                        style="margin-bottom:-4px!important;"
                      /> -->
                      <span class="outlet-button-title" :class="{ 'mr-4': plug.plugmode > 1, 'disabled__device-tile-text' : !hasAccessToTile.MODE }">{{ plug.mode }}</span>
                      <template v-if="plug.plugmode > 1">
                        <v-icon class="outlet-icon ma-0" v-if="!plug.ispublic" size="18" :color="getIconColor">lock</v-icon>
                        <v-icon class="outlet-icon ma-0" v-else size="18" :color="getIconColor">lock_open</v-icon>
                        <span class="outlet-button-title" :class="{ 'disabled__device-tile-text' : !hasAccessToTile.MODE }">{{ plug.ispublic ? "PUBLIC" : "PRIVATE" }}</span>
                      </template>
                    </button>
                  </v-flex>
                  <v-flex xs5 class="text-xs-right">
                    <button v-if="plug.plugmode > 1" class="outlet-button outlet-button-secondary mt-1" @click.stop="addUser(plug)" :class="{ 'disabled__device-tile' : !hasAccessToTile.SET_ACCESS }">
                      <span class="outlet-button-title" :class="{ 'disabled__device-tile-text' : !hasAccessToTile.SET_ACCESS }">ADD USER</span>
                    </button>
                    <button @click.stop="editPlug(plug)" class="outlet-button outlet-button-secondary outlet-arrow mt-1 ml-2" :class="{ 'disabled__device-tile' : !hasAccessToTile.SET_ACCESS }">
                      <v-icon size="18" style="margin: 0 0 -3px 0">edit</v-icon>
                    </button>
                    <button id="toggle-user-plugs-button" class="outlet-button outlet-button-secondary outlet-arrow mt-1 ml-2" v-if="plug.plugmode > 1" @click.stop="show = !show">
                      <v-progress-circular indeterminate color="orange" :size="18" small v-if="busy"></v-progress-circular>
                      <template v-else>
                        <v-icon size="18" style="margin: 0 0 -3px 0" v-if="show">keyboard_arrow_up</v-icon>
                        <v-icon size="18" style="margin: 0 0 -3px 0" v-else>keyboard_arrow_down</v-icon>
                      </template>
                    </button>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-list-tile>
        </v-list>
      </v-card-text>
      <div v-show="show && !selectable" transition="slide-y-transition" class="outlet-controls">
        <div class="text-xs-center pa-3" v-if="!users.length && !busy">
          <span class="users-title" v-if="!usersLoaded && busy">Fetching Users...</span>
          <span class="users-title" v-else>No Users</span>
        </div>
        <v-list two-line v-if="users" class="pa-0">
          <v-list-tile v-for="(user, index) in users" :key="index" class="ma-0 users-list px-2">
            <v-list-tile-content>
              <v-list-tile-title class="user-name">{{ user.username }}</v-list-tile-title>
              <v-list-tile-sub-title class="user-access-plan">
                Payment Plan:
                <span class="black--text ml-1">{{ user.plan ? user.plan.name : "-" }}</span>
                <v-icon
                  v-if="users.length == 1 && plug.plugmode == 2 && !plug.ispublic && user.plan && user.plan.params && user.plan.params.isAutoStart"
                  class="tooltip-icon ml-2"
                  v-tooltip="{
                    content: `Ability to automatically start sessions is availble to this user. If a user chooses to enable this feature, the user will responsible for all consumption on this device.`,
                    trigger: 'click hover',
                  }"
                >
                  power_settings_new
                </v-icon>
              </v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-layout row>
                <v-flex xs12 text-xs-right mr-1>
                  <v-btn id="expanded-edit-user-access-button" icon ripple @click="editUser(user, plug, 'edit')" :disabled="!hasAccessToTile.SET_ACCESS">
                    <v-icon small color="gray">edit</v-icon>
                  </v-btn>
                  <v-btn id="expanded-remove-user-access-button" icon ripple @click="editUser(user, plug, 'revoke')" class="ml-1" :disabled="!hasAccessToTile.SET_ACCESS">
                    <v-icon small color="red">close</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>
      </div>
    </v-card>
  </v-flex>
</template>

<script>
import Sort from "./../mixins/sort";
import Api from "./../library/apis/Api";
import { mapState } from "vuex";
import { OwnerProxyHelpers } from "@/library/helpers";

export default {
  name: "OutletCard",
  mixins: [Sort],
  props: {
    plug: {
      type: Object, 
      required: true 
    },
    plans: {
      type: Array, 
      required: true 
    },
    highlight: {
      type: Boolean, 
      required: false 
    },
    isHidden: {
      type: Boolean, 
      default: false 
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: "expandable"
    }
  },
  data() {
    return {
      show: false,
      rawUsers: [],
      usersLoaded: false,
      busy: false,
      actionSub: false,
      selected: false,
    };
  },
  computed: {
    ...mapState({
      managerview: (state) => state.Admin.managerview,
      role: (state) => state.Auth.role,
      preferences: state => state.Global.preferences || {},
    }),
    hasAccessToTile() {
      return { 
        MODE: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_CHANGE_MODE"),
        SET_ACCESS: OwnerProxyHelpers.isHaveAccessToFeature("DEVICE_SET_USER_ACCESS"),
      }
    },
    darkTheme() {
      return ((this.$store.state.Global.preferences || {}).settings || {}).darkTheme || false
    }, 
    users() {
      let users = this.rawUsers.map((o) => {
        let plans = this.plans;
        let planId = o.plug_payment_access_plan_id;
        o.plan = plans.find((e) => {
          return e.id === planId;
        });
        return o;
      });

      return users.sort(this.compareValues("username"));
    },
    iconClass() {
      if (this.plug.plugmode === 0) {
        return "plug-avatar-off";
      } else if (this.plug.plugmode === 1) {
        return "plug-avatar-on";
      // } else if (this.plug.plugmode === 3) {
      //   return "plug-avatar-bluetooth";
      } else if (this.plug.active) {
        return "plug-avatar-active";
      }
      return "plug-avatar-inactive";
    },
    getIconColor() {
      if (this.darkTheme && !this.hasAccessToTile.MODE) return '#333333';
      if (this.hasAccessToTile.MODE) return '#333333';
      else return '#787878';
    },
    editedPlug() {
      return this.$store.state.plugAccessUser;
    },
    getImage() {
      if (this.darkTheme && !this.hasAccessToTile.MODE) return require('./../assets/smart-icon-333.png');
      if (this.hasAccessToTile.MODE) return require('./../assets/smart-icon-333.png');
      else return require('./../assets/smart-icon-disabled.png');
    }
  },
  watch: {
    show() {
      this.$emit("show", this.show);
      if (this.show) {
        this.fetchUsers();
      }
    },
  },
  methods: {
    addUser(plug) {
      let user = {
        username: null,
        plug_payment_access_plan_id: null,
      };
      this.$emit("edit", { user, plug, mode: "add" });
    },
    // changeMode(plug, access = 0) {
    changeMode(plug) {
      // if (this.role == "admin") return;
      // plug.changeAccess = access;
      this.$emit("changeMode", plug);
    },
    editUser(user, plug, mode) {
      this.$emit("edit", { user, plug, mode });
    },
    editPlug(plug) {
      this.$store.dispatch("editPlug", { ...plug, hideDevice: this.isHidden || false });
    },
    fetchUsers() {
      this.busy = true;
      this.walletIds = [];
      const formData = { plugId: this.plug.id };
      if (!!this.managerview) formData.ownerUsername = this.managerview.owner_username;
      Api.plugUsers(this.role, formData)
        .then((data) => {
          this.rawUsers = data || [];
          this.busy = false;
          this.usersLoaded = true;
        })
        .catch((error) => {
          this.busy = false;
          console.log(error);
        });
    },
    handleClick() {
      if (this.type === "expandable") {
        if (this.plug.plugmode > 1) this.show = !this.show;
      }
      if (this.type === "selectable") {
        this.selected = !this.selected
        this.$emit("selection", this.selected);
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.highlight) this.show = true;
    });
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "editPlugUser") {
        this.$nextTick(() => {
          if (this.plug.id === this.editedPlug) this.fetchUsers();
        });
      }
      if (action.type === "loadPlugs") {
        console.log(action);
        this.$nextTick(() => {
          if (this.show) this.fetchUsers();
        });
      }
      if (action.type === "Plug/refreshPlugCards") {
        this.$nextTick(() => {
          if (this.show && action.payload.includes(this.plug.id)) this.fetchUsers();
        });
      }
    });
  },
  beforeDestroy() {
    this.actionSub();
  },
};
</script>

<style lang="sass">
.outlet-controls
	cursor: auto !important
	border-top: 1px solid #f8f8f8

.outlet-buttons
	width: 100%

.outlet-button
	border-radius: 4px
	padding: 2px 8px
	text-align: center
	background: #f8f8f8

.outlet-icon
	margin-bottom: -3px !important
	margin-left: -2px !important
	margin-right: 6px !important

.outlet-icon-smart
	margin-bottom: -5px !important

.outlet-button:hover
	background: #f2f2f2 !important

.outlet-button-title
	display: inline-block
	font-size: .78em !important
	text-transform: uppercase
	color: #333333

.outlet-arrow
	padding-left: 4px
	padding-right: 4px

.plug-tile-details
	width: 80%
	.plug-tile-details-text
		overflow: hidden
		text-overflow: ellipsis
		width: 100%
		white-space: nowrap
	.plug-tile-details-long-text
		overflow: hidden
		text-overflow: ellipsis
		display: -webkit-box
		-webkit-line-clamp: 2
		-webkit-box-orient: vertical
		width: 100%
	@media only screen and (max-width: 1500px)
		.outlet-buttons
			display: none
	@media only screen and (max-width: 1390px)
		width: 70%
	@media only screen and (max-width: 1120px)
		.outlet-buttons
			display: none
	@media only screen and (max-width: 990px)
		width: 80%
		.outlet-buttons
			display: flex
	@media only screen and (max-width: 960px)
		width: 90%

.outlet-card__container
	flex-direction: column
	width: 100%
	.outlet__container
		min-height: 92px

.outlet-card__footer
	display: none
	.outlet-buttons
		line-height: 1.8em
		font-size: .75em
		color: #949fa8
		font-weight: 400
	@media only screen and (max-width: 1500px)
		display: flex
	@media only screen and (max-width: 1120px)
		display: flex
	@media only screen and (max-width: 990px)
		display: none

.v-list__tile
	width: 100%
</style>
<style lang="scss">
.user-access-plan {
  display: flex;
  align-items: center;
}
.plug-avatar {
  &-on {
    background: rgba(0, 172, 193, 0.1);
    border-color: rgba(0, 172, 193, 0.1);
  }
  &-off {
    background: rgba(244, 67, 54, 0.1);
    border-color: rgba(244, 67, 54, 0.1);
  }
  &-active {
    background: rgba(76, 175, 80, 0.1);
    border-color: rgba(76, 175, 80, 0.1);
  }
  &-inactive {
    background: rgba(255, 165, 0, 0.1);
    border-color: rgba(255, 165, 0, 0.1);
  }
  .v-list__hidden { 
    background: grey !important; 
  } 
}
.outlet-card {
  &__hidden {
    .v-card__text {
      background-color: #eae1e1;
      .v-list {
        background-color: #eae1e1;
      }
    }
  }
}
.session-tile {
  &__selected {
    background-color: #0d920d!important;
    .v-list {
      background-color: #0d920d!important;
    }
    .plug-tile-details-text {
      color: white!important;
      & > * {
        color: white!important;
      }
    }
    .plug-lists-avatar {
      background-color: white!important;
    }
    &.session-tile__dark {
      background-color: #0e660e!important;
      .v-list {
        background-color: #0e660e!important;
      }
    }
  }
}
.disabled {
  &__device-tile { 
    background-color: rgb(221, 218, 218);
    &-text { 
      color: #9a9a9a;
    }
  }
}
</style>
