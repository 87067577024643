<template>
  <div>
    <v-toolbar id="header" height="58" fixed :class="{ 'logo-adjust': !responsive && showSidebar }">
      <v-toolbar-side-icon id="header-hamburger" @click.stop="showSidebar = !showSidebar"></v-toolbar-side-icon>
      <img id="header-logo" class="plugzio-logo" src="../assets/PlugzioLogoHorizontal.png" />
      <v-spacer></v-spacer>
      <div class="title-menu text-xs-right">
        <span id="header-email">{{ actAsOwner !== null ? `Logged in as ${actAsOwner}` : user }}</span>
      </div>
    </v-toolbar>
    <v-navigation-drawer
      v-if="isDashboard && responsive !== null && !!role"
      v-model="showSidebar"
      app
      dark
      floating
      persistent
      mobile-break-point="991"
      width="270"
      class="main-drawer-menu drawer pt-0"
      :class="[`drawer__${role}`]"
      hide-overlay
    >
      <v-layout id="navigation-bar" class="fill-height pa-0" tag="v-list" column>
        <template v-if="actAsOwnerOptions.length > 1">
          <v-list class="pt-0 pb-0 mt-0">
            <v-list-tile
              class="v-list-item"
              :class="{ active: $route.name === 'owner-account-selection' }"
              @click="$router.push({ name: 'owner-account-selection' })"
            >
              <v-list-tile-action>
                <v-icon>account_circle</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>Account Selection</v-list-tile-title>
            </v-list-tile>
          </v-list>
          <hr style="border-top: 1px solid" />
        </template>

        <v-list class="pt-0 pb-0 mt-0">
          <template v-for="(item, index) in menu">
            <v-list-tile
              v-if="!item.group || (item.group && item.action)"
              :id="item.elementId"
              class="v-list-item"
              :class="{ active: $route.name === item.route }"
              :key="index"
              @click.stop="handleGroupClick(item)"
            >
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-title>{{ item.label }}</v-list-tile-title>
              <v-list-tile-action v-if="item.action">
                <img v-if="role == 'owner'" src="./../assets/warning-owner.png" class="mr-0 ml-3" width="24px" />
                <img v-else src="./../assets/warning-user.png" class="mr-0 ml-3" width="24px" />
              </v-list-tile-action>
            </v-list-tile>
            <v-list-group 
              v-else
              :key="index" 
              v-model="item.expand"
              no-action
            >
              <template v-slot:activator>
                <v-list-tile class="v-list-item" :key="index" @click.stop="handleGroupClick(item)" :id="item.elementId">
                  <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-tile-action>
                  <v-list-tile-title>
                    {{ item.label }}
                  </v-list-tile-title>
                </v-list-tile>
              </template>

              <v-list-tile
                class="v-list-item"
                :class="{ active: $route.name === subItem.route }"
                v-for="(subItem, i_sub_item) in item.childrens"
                :key="i_sub_item"
                @click.stop="$router.push({ name: subItem.route })"
              >
                <v-list-tile-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-title>
                  {{ subItem.label }}
                </v-list-tile-title>
              </v-list-tile>
            </v-list-group>
          </template>
        </v-list>
        <v-spacer></v-spacer>
        <v-list class="pt-0 mt-0">
          <v-list-tile
            :class="{ active: $route.name === `${roleSetting.routePrefix}settings` }"
            id="navigation-bar-settings"
            class="v-list-item"
            @click.stop="$router.push({ name: `${roleSetting.routePrefix}settings` })"
          >
            <v-list-tile-action>
              <v-icon>settings</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Account Settings</v-list-tile-title>
          </v-list-tile>
          <v-list-tile 
            id="navigation-bar-article"  
            class="v-list-item"  
            @click.stop="gotoHelpArticle"
          > 
            <v-list-tile-action> 
              <v-icon>articles</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>
              Help Articles
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile 
            id="navigation-bar-intercom"
            class="v-list-item"
            @click.stop="showIntercom"
          >
            <v-list-tile-action style="min-width: 45px">
              <v-icon>feedback</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Chat With Us</v-list-tile-title>
          </v-list-tile>
          <v-list-tile id="navigation-bar-logout" class="v-list-item" @click.stop="showLogoutDialog">
            <v-list-tile-action>
              <v-icon>exit_to_app</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
    <v-dialog v-model="confirmModal" max-width="290">
      <v-card>
        <v-card-title>
          <h3 id="logout-title">Confirm</h3>
        </v-card-title>
        <v-card-text id="logout-message">Are you sure you want to logout?</v-card-text>
        <v-card-actions>
          <v-btn color="gray" flat="flat" id="logout-cancel" @click="confirmModal = false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            id="logout-confirm"
            color="green darken-1"
            flat="flat"
            @click="
              confirmModal = false;
              logout();
            "
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import mixin from "./../mixins";
import { mapState, mapGetters } from "vuex";
import { SortHelpers } from "@/library/helpers"

export default {
  mixins: [mixin],
  data: () => ({
    responsive: null,
    confirmModal: false,
    disableWatcher: true,
    user: null,
    menu: [],
  }),
  computed: {
    ...mapState({
      deviceview: (state) => state.Admin.deviceview,
      managerview: (state) => state.Admin.managerview,
      userview: (state) => state.Admin.userview,
      canActAsUsers: (state) => state.OwnerProxy.canActAsUsers,
    }),
    ...mapGetters(["plugsCount", "plansCount", "walletsCount"]),
    userMenu() {
      return [
        {
          elementId: "navigation-bar-dashboard",
          label: "Dashboard",
          icon: "power",
          route: "dashboard",
        },
        {
          elementId: "navigation-bar-session-history",
          label: "Session History",
          icon: "history",
          route: "sessions",
        },
        {
          elementId: "navigation-bar-wallets",
          label: "Wallets",
          icon: "account_balance_wallet",
          route: "wallets",
          action: this.walletsCount === 0 && this.walletsCount !== null,
        },
      ];
    },
    ownerMenu() {
      return [
        {
          elementId: "navigation-bar-dashboard",
          label: "Dashboard",
          icon: "dashboard",
          route: `${this.roleSetting.routePrefix}dashboard`,
        },
        {
          elementId: "navigation-bar-device-management",
          label: "Device Management",
          icon: "power",
          route: `${this.roleSetting.routePrefix}outlet-management`,
          action: this.plugsCount === 0 && this.plugsCount !== null,
          group: true,
          expand: false, 
          childrens: [
            {
              label: "Network Configuration",
              icon: "wifi",
              route: `${this.roleSetting.routePrefix}device-network-management`, 
            },
            {
              label: "Load Balancing",
              icon: "balance",
              route: `${this.roleSetting.routePrefix}load-balancing`,
            },
          ],
        },
        {
          elementId: "navigation-bar-payment-plans",
          label: "Payment Plans",
          icon: "descriptions",
          route: `${this.roleSetting.routePrefix}access-plans`,
          action: this.plansCount === 0 && this.plansCount !== null,
        },
        {
          elementId: "navigation-bar-wallets",
          label: "Wallets",
          icon: "account_balance_wallet",
          route: `${this.roleSetting.routePrefix}wallets`,
          action: this.walletsCount === 0 && this.walletsCount !== null,
        },
        {
          elementId: "navigation-bar-billing",
          label: "Service Subscription",
          icon: "receipt",
          route: `${this.roleSetting.routePrefix}service-subscription`,
          action: (this.plugsCount === 0 && this.plugsCount !== null) || this.$store.getters.plugClosestExpirationTimestamp,
        },
      ];
    },
    adminMenu() {
      return [
        {
          elementId: "navigation-bar-dashboard",
          label: "APIs",
          icon: "dashboard",
          route: `${this.roleSetting.routePrefix}dashboard`,
        },
        {
          elementId: "navigation-bar-managerview",
          label: "Manager View",
          icon: "account_box",
          route: `${this.roleSetting.routePrefix}managerview`,
        },
        {
          elementId: "navigation-bar-userview",
          label: "User View",
          icon: "account_circle",
          route: `${this.roleSetting.routePrefix}userview`,
        },
        {
          elementId: "navigation-bar-deviceview",
          label: "Device View",
          icon: "power",
          route: `${this.roleSetting.routePrefix}deviceview`,
        },
        {
          elementId: "navigation-bar-configure-device-subscriptions",
          label: "Device Subscriptions",
          icon: "loyalty",
          name: "subscriptionview",
          group: true,
          expand: false,
          childrens: [
            {
              label: "Subscription Time",
              icon: "access_time",
              route: `${this.roleSetting.routePrefix}configure-device-subscriptions-time`,
            },
            {
              label: "Subscription Plan",
              icon: "list_alt",
              route: `${this.roleSetting.routePrefix}configure-device-subscriptions-plan`,
            },
          ],
        }
      ];
    },
    menus() {
      return {
        owner: this.ownerMenu,
        admin: this.adminMenu,
        user: this.userMenu,
      };
    },
    showDatePicker() {
      return (
        this.$route.name === "sessions" ||
        this.$route.name === `${this.roleSetting.routePrefix}wallets` ||
        (this.role === "owner" && this.$route.name === `${this.roleSetting.routePrefix}dashboard`)
      );
    },
    showSidebar: {
      get() {
        return this.$store.state.Global.showSidebar
      },
      set(v) {
        this.$store.commit("Global/setState", { showSidebar: !!v });
      }
    },
    actAsOwner: {
      get() {
        return this.$store.state.OwnerProxy.actAs
      },
      set(v) {
        this.$store.dispatch("OwnerProxy/setActAs", v);
        this.$store.dispatch("initialize")
      }
    },
    actAsOwnerOptions() {
      return [
        { value: null, text: 'My Account' },
        ...this.canActAsUsers.map(p => ({ value: p.owner_username, text: p.owner_username })).sort(SortHelpers.compareValues("text"))
      ]
    }
  },
  methods: {
    handleGroupClick(item) {
      if (item.group) {
        item.expand = !item.expand;
      }

      this.menu.forEach(menuItem => {
        if (menuItem.group && menuItem.expand && menuItem !== item) {
          menuItem.expand = false;
        }
      });

      if (item.elementId === 'navigation-bar-device-management') {
          item.expand = true;
      }

      this.$router.push({ name: item.route });
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
        this.$store.dispatch("responsive", true);
      } else {
        this.responsive = false;
        this.$store.dispatch("responsive", false);
      }
    },
    setDrawer(value) {
      if (this.responsive) this.showSidebar = value;
    },
    onSwipe(show) {
      const dialogActiveElement = document.querySelector(".v-dialog__content--active");
      if (!dialogActiveElement && !!this.responsive) this.showSidebar = show;
    },
    showIntercom() {
      this.$store.dispatch('Global/toggleIntercom');
    },
    showLogoutDialog() {
      this.$intercom.hide()
      this.setDrawer(false);
      this.$nextTick(() => {
        this.confirmModal = true;
      });
    },
    gotoHelpArticle() { 
      window.open('http://help.plugz.io/')
    }
  },
  watch: {
    role() {
      this.menu = this.menus[this.role];
    },
    menus: {
      handler() {
        if (!this.deviceview && !this.managerview) this.menu = this.menus[this.role];
      },
      deep: true,
    },
    deviceview: {
      handler(plug) {
        let menu = JSON.parse(JSON.stringify(this.menu));
        this.menu = [];
        const menuIndex = menu.findIndex((m) => m.elementId == "navigation-bar-deviceview");
        if (!plug) {
          menu[menuIndex] = {
            elementId: "navigation-bar-deviceview",
            label: "Device View",
            icon: "power",
            route: `${this.roleSetting.routePrefix}deviceview`,
            name: "deviceview",
          };
        } else {
          menu[menuIndex] = {
            elementId: "navigation-bar-deviceview",
            label: "Device View",
            icon: "power",
            route: `${this.roleSetting.routePrefix}deviceview`,
            name: "deviceview",
            group: true,
            expand: true,
            childrens: [
              {
                label: "Dashboard",
                icon: "dashboard",
                route: "admin-deviceview-dashboard",
              },
              {
                label: "Info & Logs",
                icon: "info",
                route: "admin-deviceview-info-and-logs",
              },
              {
                label: "Heartbeat Analytics",
                icon: "monitor_heart",
                route: "admin-deviceview-heartbeat-analytics",
              },
            ],
          };
        }
        this.$nextTick(() => (this.menu = menu));
      },
      deep: true,
    },
    managerview: {
      handler(owner) {
        let menu = JSON.parse(JSON.stringify(this.menu));
        this.menu = [];
        const menuIndex = menu.findIndex((m) => m.elementId == "navigation-bar-managerview");
        if (!owner) {
          menu[menuIndex] = {
            elementId: "navigation-bar-managerview",
            label: "Manager View",
            icon: "account_box",
            route: `${this.roleSetting.routePrefix}managerview`,
            name: "managerview",
          };
        } else {
          menu[menuIndex] = {
            elementId: "navigation-bar-managerview",
            label: "Manager View",
            icon: "account_box",
            route: `${this.roleSetting.routePrefix}managerview`,
            name: "managerview",
            group: true,
            expand: true,
            childrens: [
              {
                label: "Dashboard",
                icon: "dashboard",
                route: "admin-managerview-dashboard",
              },
              {
                label: "Device Management",
                icon: "power",
                route: "admin-managerview-outlet-management",
              },
              {
                label: "Network Configuration",
                icon: "wifi",
                route: "admin-managerview-device-network-management",
              },
              {
                label: "Load Balancing",
                icon: "balance",
                route: "admin-managerview-load-balancing",
              },
              {
                label: "Payment Plans",
                icon: "description",
                route: "admin-managerview-paymentplans",
              },
              {
                label: "Wallets",
                icon: "account_balance_wallet",
                route: "admin-managerview-wallets",
              },
              {
                label: "Subscription Service",
                icon: "loyalty",
                route: "admin-managerview-subscription",
              },
              {
                label: "Configuration",
                icon: "settings",
                route: "admin-managerview-configuration",
              },
            ],
          };
        }
        this.$nextTick(() => (this.menu = menu));
      },
      deep: true,
    },
    userview: {
      handler(user) {
        let menu = JSON.parse(JSON.stringify(this.menu));
        this.menu = [];
        const menuIndex = menu.findIndex((m) => m.elementId == "navigation-bar-userview");
        if (!user) {
          menu[menuIndex] = {
            elementId: "navigation-bar-userview",
            label: "User View",
            icon: "account_circle",
            route: `${this.roleSetting.routePrefix}userview`,
            name: "userview",
          };
        } else {
          menu[menuIndex] = {
            elementId: "navigation-bar-userview",
            label: "User View",
            icon: "account_circle",
            route: `${this.roleSetting.routePrefix}userview`,
            name: "userview",
            group: true,
            expand: true,
            childrens: [
              {
                elementId: "navigation-bar-userview-dashboard",
                label: "Dashboard",
                icon: "dashboard",
                route: "admin-userview-dashboard",
              },
              {
                elementId: "navigation-bar-userview-session-history",
                label: "Session History",
                icon: "history",
                route: "admin-userview-sessions",
              },
              {
                elementId: "navigation-bar-userview-wallets",
                label: "Wallets",
                icon: "account_balance_wallet",
                route: "admin-userview-wallets",
              },
              {
                label: "Configuration",
                icon: "settings",
                route: "admin-userview-configuration",
              }
            ],
          };
        }
        this.$nextTick(() => (this.menu = menu));
      },
      deep: true,
    },
  },
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
    if (!this.responsive) this.showSidebar = true;

    // swipe detect
    let mc = new Hammer(document.getElementById("app"));
    mc.get("pan").set({ threshold: 150 });
    mc.on("panleft panright", (ev) => {
      this.onSwipe(ev.type === "panright");
    });

    this.$store.dispatch("initialize")

    this.user = localStorage.getItem("email");
    this.menu = this.menus[this.role];
  },
  beforeDestroy() {
    window.removeEventListener("onSwipe", this.onSwipe);
  },
};
</script>

<style lang="scss">
.v-list__group__items--no-action .v-list__tile {
  padding-left: 36px;
}
.v-toolbar--fixed {
  z-index: 200;
}
.v-navigation-drawer {
  z-index: 1001;
}
</style>
<style lang="scss" scoped>
@import "./../styles/colors";

.plugzio-logo {
  height: 30px;
  margin-left: 5px;
}

.logo-adjust {
  padding-left: 270px !important;
}

.drawer {
  &__user {
    background: $user-color;
    .account-info {
      background: $user-dark-color;
    }
    .v-list-item:hover {
      background: $user-highlight-color;
    }
    .active {
      background: $user-active-color !important;
    }
  }

  &__owner {
    background: $owner-color;
    .account-info {
      background: $owner-dark-color;
    }
    .v-list-item:hover {
      background: $owner-highlight-color;
    }
    .active {
      background: $owner-active-color !important;
    }
  }
  &__admin {
    background: $admin-color;
    .account-info {
      background: $admin-dark-color;
    }
    .v-list-item:hover {
      background: $admin-highlight-color;
    }
    .active {
      background: $admin-active-color !important;
    }
  }
}

.v-list-item:hover {
  cursor: pointer;
}

.v-list__tile__action {
  min-width: 45px !important;
}

.account-info {
  h3 {
    font-weight: 300;
    font-size: 1em;
  }
}
</style>
<style lang="scss">
.navbar-change-account-selection {
  height: 5.5rem;
  .v-list__tile {
    height: 100%;
    .v-select__selections {
      padding-top: 0px!important;
    }
    .v-input__append-inner {
      margin-top: 14px!important;
    }
  }
}
</style>
