<template>
  <div>
    <v-layout row class="mt-2 mb-0">
      <v-flex xs6>
        <v-text-field
          max-width="300"
          class="session-search mb-0 ml-2"
          v-model.lazy="search"
          :placeholder="!enableRegexSearch ? 'Search' : 'Search RegEx'"
          autocomplete="false"
          :disabled="loading"
          prepend-icon="search"
        >
          <template v-if="enableRegexSearch" v-slot:prepend-inner>
            <v-flex>
              <i class="search-slash-icon" style="bottom:-4px;position:relative">/</i>
            </v-flex>
          </template>
          <template v-slot:append>
            <v-flex align-self-center class="search-append-div">
              <i v-if="enableRegexSearch" class="search-slash-icon mr-2" style="bottom:3px;position:relative">/</i>

              <v-icon
                @click="enableRegexSearch = !enableRegexSearch"
                class="tooltip-icon"
                :color="enableRegexSearch ? `blue lighten-2` : ''"
                v-tooltip="{
                  content: tooltip,
                  trigger: 'hover',
                }"
                >settings_applications</v-icon
              >
            </v-flex>
          </template>
        </v-text-field>
      </v-flex>
      <v-flex xs6 class="text-xs-right pt-3 pr-2" v-if="loading">
        <v-progress-circular indeterminate color="orange" :size="24"></v-progress-circular>
      </v-flex>
      <v-flex xs6 class="text-xs-right page-toolbar pt-3" v-else>
        <v-menu offset-y>
          <v-btn slot="activator" value="status" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Status: &nbsp;
              <strong>{{ filter.status.label }}</strong>
            </span>
            <v-icon
              class="hidden-md-and-up"
              :class="{
                'success--text': filter.status.property === 1,
                'warning--text': filter.status.property === 0,
              }"
            >
              power
            </v-icon>
          </v-btn>
          <v-list id="status-filter" hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.status.property === item.property }"
              v-for="(item, index) in filter.statuses"
              :key="index"
              @click="filter.status = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <v-btn slot="activator" value="order" small flat class="page-toolbar-btn">
            <span class="hidden-sm-and-down">
              Sort: &nbsp;
              <strong>{{ filter.sorting.label }}</strong>
            </span>
            <v-icon class="hidden-md-and-up">format_list_bulleted</v-icon>
          </v-btn>
          <v-list id="sort-filter" hover>
            <v-list-tile
              class="pointer"
              :class="{ 'menu-active': filter.sorting.property === item.property }"
              v-for="(item, index) in filter.sort"
              :key="index"
              @click="filter.sorting = item"
            >
              <small>{{ item.label }}</small>
            </v-list-tile>
          </v-list>
        </v-menu>
        <v-btn flat small class="page-toolbar-btn" @click="filter.desc = !filter.desc" :disabled="!filter.sorting.property">
          <v-icon :class="{ 'rotate-icon': !filter.desc }">sort</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout column wrap>
      <template v-if="sessions.length > 0">
        <v-layout row wrap>
          <v-flex xl3 lg4 md6 xs12 v-for="(session, index) in paginatedSessions" :key="index">
            <v-card class="session-tile session-history owner-session ma-2" :class="{ 'session-active': session.active }" @click="openDetails(session)">
              <v-card-text class="pa-1 pt-2" :class="{ 'owner-session-active': session.active }">
                <v-list three-line>
                  <v-list-tile avatar class="ma-0">
                    <v-list-tile-avatar>
                      <div
                        class="plug-avatar"
                        :class="{
                          'plug-avatar-inactive': session.statusCode == 0,
                          'plug-avatar-active': session.statusCode == 1,
                          'plug-avatar-stale': session.statusCode == 2,
                        }"
                      >
                        <img src="./../assets/plug-off.png" v-if="session.statusCode == 0" />
                        <img src="./../assets/plug-on.png" v-else-if="session.statusCode == 1" />
                        <img src="./../assets/not-allowed.svg" v-else class="pa-2" />
                      </div>
                    </v-list-tile-avatar>
                    <v-list-tile-content class="plug-tile-details">
                      <div>
                        Device ID:
                        <span class="black--text">{{ session.plug_identifier }}</span>
                      </div>
                      <div>
                        Description:
                        <span class="black--text">{{ session.plug ? session.plug.description : null }}</span>
                      </div>
                      <div>
                        User:
                        <span class="black--text">{{ session.username }}</span>
                      </div>
                      <div>
                        Start Time:
                        <span class="black--text">{{ session.starttime_formatted }}</span>
                      </div>

                      <div v-if="session.active">
                        Last Updated:
                        <span class="black--text">{{ session.update_formatted }}</span>
                      </div>
                      <div v-else>
                        End Time:
                        <span class="black--text">{{ session.endtime_formatted }}</span>
                      </div>

                      <v-icon
                        v-if="session.statusCode == 2"
                        class="tooltip-icon session-stale-info"
                        v-tooltip="{
                          content: 'Session has not been updated recently. Device maybe offline or powered off.',
                          placement: 'top-center',
                          trigger: 'click hover',
                        }"
                        >info</v-icon
                      >
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card-text>
              <v-layout row wrap class="session-details">
                <v-flex xs4 class="session-detail py-3 text-xs-center">
                  <span style="font-weight: 500;">{{ session.duration }}</span>
                  <small class="text-session-detail">DURATION</small>
                </v-flex>
                <v-flex xs4 class="session-detail py-3 text-xs-center">
                  <span style="font-weight: 500;">{{ session.consumption }}</span>
                  <small class="text-session-detail">CONSUMPTION</small>
                </v-flex>
                <v-flex xs4 class="session-detail py-3 text-xs-center">
                  <span style="font-weight: 500;">{{ session.amount }}</span>
                  <small class="text-session-detail">
                    REVENUE
                    <span v-if="session.wallet" style="font-weight: 500;">({{ session.wallet.currency }})</span>
                  </small>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <div class="text-xs-center">
          <v-pagination
            class="mt-4"
            v-model="page"
            :length="Math.ceil((sessions || []).length / dataLimit)"
            circle
            :total-visible="7"
          />
        </div>
      </template>
      <v-flex v-else xs12 class="text-xs-center">
        <p class="subheading">
          No user sessions
        </p>
      </v-flex>
    </v-layout>
    <SessionLog
      :show-email-and-description="true"
      :selected="selectedSession"
      :mode="role"
      :is-owner="role == 'owner'"
    >
    <div> 
      <v-btn
        v-if="role != 'admin'"
        small
        dark
        color="green"
        class="plugzio-button create-button mx-2 px-3 my-1"
        style="min-width: auto; height: 30%; border-radius: 30px;"
        round
        outline
        :loading="loading"
        @click.stop="gotoOutlet(selectedSession.plug_id)"
      >
        <v-icon size="18" class="ma-0">settings</v-icon>
        <span 
          class="ml-1 hidden-md-and-down" 
          key="description"
        >
          MANAGE DEVICE
        </span>
      </v-btn>
    </div>
    </SessionLog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import auth from "./../mixins/auth";
import sort from "./../mixins/sort";
import sessionsFormat from "./../mixins/sessionsFormat";
import SessionLog from "./modals/SessionLog";
import SessionHelpers from "./../library/helpers/SessionHelpers";
import Api from "./../library/apis/Api";

const DATA_LIMIT = 24
export default {
  mixins: [auth, sort, sessionsFormat],
  components: {
    SessionLog,
  },
  data() {
    return {
      actionSub: null,
      // enableRegexSearch: false,
      // search: null,
      filter: {
        desc: true,
        status: {
          label: "All",
          property: "all",
        },
        statuses: [
          {
            label: "All",
            property: "all",
          },
          {
            label: "On",
            property: 1,
          },
          {
            label: "Off",
            property: 0,
          },
          {
            label: "Stale",
            property: 2,
          },
        ],
        sorting: {
          label: "End Time",
          property: "endtime",
        },
        sort: [
          {
            label: "Device ID",
            property: "plug_identifier",
          },
          {
            label: "Description",
            property: "plug_description",
          },
          {
            label: "User",
            property: "username",
          },
          {
            label: "Start Time",
            property: "starttime",
          },
          {
            label: "End Time",
            property: "endtime",
          },
          {
            label: "Duration",
            property: "duration_raw",
          },
          {
            label: "Consumption",
            property: "total_consumption",
          },
          {
            label: "Revenue",
            property: "total_cost",
          },
        ],
      },
      formatted_filters: {
        status: "all",
        sort_by: "endtime",
        sort_direction_desc: true,
        search: null,
        enableRegexSearch: false,
      },
      currentTime: {
        since: null,
        till: null,
      },
      page: 1,
      stats: null,
      sessionInterval: null,
      selectedSession: null,
      tooltip: `Allows search results to be filtered using regular expression (RegEx) patterns. Regex flavour is JavaScript/ECMAScript.<br/>
                Examples:
                <ul>
                  <li>(Jan)|(Aug) - Pattern match results with either "Jan" or "Aug".</li>
                  <li>(My).{1,}(Unit) - Pattern match results that have pattern starting with "My and ending with "Unit" (e.g. "MySpecialUnit")</li>
                </ul>`,
    };
  },
  computed: {
    ...mapState({
      plugs: (state) => state.plugs,
      wallets: (state) => state.wallets || null,
      deviceview: (state) => state.Admin.deviceview,
      adminActiveView: (state) => state.Admin.activeView,
      loading: (state) => state.loading,
    }),
    ...mapGetters(["storedTime"]),
    ...mapGetters("Admin", {
      viewAsOwner: "viewAsOwner",
    }),
    dataLimit: () => DATA_LIMIT,
    search: {
      get() {
        return this.$store.state.Filter.search;
      },
      set(v) {
        this.$store.commit("Filter/set", { search: v });
      },
    },
    enableRegexSearch: {
      get() {
        return this.$store.state.Filter.enableRegexSearch;
      },
      set(v) {
        this.$store.commit("Filter/set", { enableRegexSearch: v });
      },
    },
    sessions() {
      this.formatted_filters = {
        status: this.filter.status.property,
        sort_by: this.filter.sorting.property,
        sort_direction_desc: this.filter.desc,
        search: this.search,
        enableRegexSearch: this.enableRegexSearch,
      };
      if (this.adminActiveView == "deviceview" && !!this.deviceview) {
        this.formatted_filters.plug_identifier = this.deviceview.identifier;
        this.$emit("filters", this.formatted_filters);
      }
      const sessions = JSON.parse(JSON.stringify(this.$store.getters.sessions));
      return SessionHelpers.filter(sessions, this.formatted_filters);
    },
    paginatedSessions() {
      return this.sessions.slice((this.page - 1) * this.dataLimit, this.page * this.dataLimit)
    },
  },
  watch: {
    sessions() {
      const stats = SessionHelpers.getStats(this.sessions);
      this.stats = stats;
      this.$emit("filters", this.formatted_filters);
      this.$store.commit("Session/setFilters", this.formatted_filters);
    },
    stats() {
      this.$store.dispatch("setStats", this.stats);
    },
    storedTime() {
      this.getSessions();
    },
    formatted_filters() {
      this.page = 1
    }
  },
  methods: {
    clearCounters() {
      clearInterval(this.sessionInterval);
    },
    initAutoRefresh() {
      if (!this.sessionInterval) {
        this.sessionInterval = setInterval(() => {
          this.getSessions();
        }, SESSION_REFRESH_INTERVAL);
      }
    },
    getSessions() {
      this.$store.dispatch("loading", true);
      const formData = { since: this.storedTime.since, till: this.storedTime.till };
      if (this.role == "admin") formData.ownerUsername = this.viewAsOwner.username;
      Api.sessions(this.role == "admin" ? "admin/owner" : "owner", formData)
        .then((sessions) => {
          this.$store.dispatch("setSessions", sessions || []);
        })
        .catch((err) => this.$store.dispatch("snackbar", { message: "Could not complete requests..." }))
        .finally(() => (this.$store.dispatch("loading", false)));
    },
    openDetails(session) {
      this.selectedSession = null;
      this.$nextTick(() => {
        this.selectedSession = session;
      });
    },
    gotoOutlet(plugIdentifier) {
      this.$store.dispatch("outletHighlight", plugIdentifier);
      const routeName = this.role === 'admin' ? 'managerview-outlet-management' : 'outlet-management';
      this.$nextTick(() => {
        this.$router.push({
          name: `${this.roleSetting.routePrefix}${routeName}`,
        });
      });
    },
  },
  mounted() {
    this.actionSub = this.$store.subscribeAction((action) => {
      if (action.type === "setTime") this.initAutoRefresh();
    });
  },
  beforeDestroy() {
    this.actionSub();
    clearInterval(this.sessionInterval);
  },
};
</script>

<style lang="scss">
.text-session-detail { 
  font-size: 80%;
  color: lightslategray;
}
.session-search {
  max-width: 420px;
  margin-top: -1px;
}
.search-slash-icon {
  color: rgba(0, 0, 0, 0.87);
}
.search-append-div {
  white-space: nowrap;
}
.owner-session {
  cursor: pointer;
}
.plug-avatar {
  width: 78px;
  height: 78px;
  @media only screen and (max-width: 560px) {
    height: 64px;
    width: 64px;
  }
}
.plug-tile-details {
  line-height: 1.8em;
  @media only screen and (max-width: 560px) {
    line-height: 1.6em;
  }
}

.plug-avatar-active {
  background: rgba(76, 175, 80, 0.1);
  border-color: rgba(76, 175, 80, 0.1);
}
.plug-avatar-inactive {
  background: rgba(255, 165, 0, 0.1);
  border-color: rgba(255, 165, 0, 0.1);
}
</style>
